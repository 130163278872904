const React = require('react');

function TechnologiesPythonInline (props) {
    return React.createElement("svg",props,[React.createElement("defs",{"key":0},[React.createElement("linearGradient",{"x1":"50%","y1":"100%","x2":"50%","y2":"0%","id":"nodeb","key":0},[React.createElement("stop",{"stopColor":"#5E67DF","offset":"0%","key":0}),React.createElement("stop",{"stopColor":"#00EBAA","offset":"100%","key":1})]),React.createElement("linearGradient",{"x1":"50%","y1":"100%","x2":"50%","y2":"0%","id":"noded","key":1},[React.createElement("stop",{"stopColor":"#5E67DF","offset":"0%","key":0}),React.createElement("stop",{"stopColor":"#00EBAA","offset":"100%","key":1})]),React.createElement("path",{"id":"nodea","d":"M0 .013h48.162v46.398H0z","key":2})]),React.createElement("g",{"fill":"none","fillRule":"evenodd","key":1},[React.createElement("g",{"key":0},[React.createElement("mask",{"id":"nodec","fill":"#fff","key":0},React.createElement("use",{"xlinkHref":"#nodea"})),React.createElement("path",{"d":"M23.563 4.802c1.562-.002 2.83 1.214 2.83 2.715v.005c.002 1.502-1.263 2.72-2.824 2.721h-.006c-1.561.001-2.829-1.215-2.83-2.716v-.005c-.001-1.501 1.264-2.72 2.825-2.72h.005zM32.24.013C15.752.013 16.78 6.886 16.78 6.886l.018 7.12h15.734v2.138H10.55S0 14.994 0 30.984c0 15.992 9.209 15.424 9.209 15.424h5.495v-7.42s-.296-8.852 9.062-8.852H39.37s8.767.137 8.767-8.144V8.3S49.469.013 32.238.013z","fill":"url(#nodeb)","mask":"url(#nodec)","key":1})]),React.createElement("path",{"d":"M41.423 58.21c-1.563.001-2.83-1.215-2.832-2.717v-.005c-.002-1.502 1.264-2.721 2.827-2.723h.005c1.563-.001 2.83 1.216 2.832 2.718v.004c.002 1.502-1.264 2.721-2.827 2.723h-.005zM32.743 63c16.496 0 15.466-6.876 15.466-6.876L48.191 49H32.448v-2.139h21.996S65 48.012 65 32.013s-9.214-15.432-9.214-15.432h-5.499v7.424s.297 8.856-9.066 8.856H25.607s-8.773-.136-8.773 8.15V54.71S15.502 63 32.742 63z","fill":"url(#noded)","key":1})])]);
}

TechnologiesPythonInline.defaultProps = {"width":"65","height":"63","viewBox":"0 0 65 63"};

module.exports = TechnologiesPythonInline;

TechnologiesPythonInline.default = TechnologiesPythonInline;
