const React = require('react');

function VisionIcon2Inline(props) {
    return React.createElement("svg", props, React.createElement("g", {
        "strokeWidth": "2",
        "stroke": "#00EBAA",
        "fill": "none",
        "fillRule": "evenodd",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
    }, React.createElement("path", {"d": "M23 28l6-6M12 5l-6 6M9.5 14.5L3 8l6-6 6.5 6.5M22.5 15.5L29 22l1 7-7-1-6.5-6.5M2 22L22 2l7 7L9 28.998zM12 12l3 3M9 15l2 2M18 6l3 3M15 9l2 2M6 18l3 3"})));
}

VisionIcon2Inline.defaultProps = {"width": "31", "height": "31"};

module.exports = VisionIcon2Inline;

VisionIcon2Inline.default = VisionIcon2Inline;
