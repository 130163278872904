import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MainPage from './Main Page/main page';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import TermsOfUse from "./Main Page/trem-of-use";
import PrivacyPolicy from "./Main Page/privacy-policy";
import {Cookies} from "./Components";

const root = ReactDOM.createRoot(document.getElementById('root'));




root.render(

    <BrowserRouter>

    <Routes>

            <Route index element={  <MainPage />} />
            <Route path="terms-of-use/" element={<TermsOfUse/>} />
            <Route path="privacy-policy/" element={<PrivacyPolicy/>} />


    </Routes>
        <Cookies/>
</BrowserRouter>
);

