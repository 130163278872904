var React = require('react');

function HireIcon6Inline(props) {
    return React.createElement("svg", props, React.createElement("g", {
        "transform": "translate(1.204 1.193)",
        "strokeWidth": "2",
        "stroke": "#00EBAA",
        "fill": "none",
        "fillRule": "evenodd",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
    }, [React.createElement("path", {
        "d": "M10.962 7.548l-5.2-5.195M2.341 5.77l5.201 5.195M19.05 10.965l5.201-5.195M20.83 2.353l-5.201 5.195M15.629 19.043l5.201 5.195M24.251 20.821l-5.201-5.195M7.542 15.626l-5.201 5.195M5.762 24.238l5.2-5.195",
        "key": 0
    }), React.createElement("circle", {
        "cx": "13.296",
        "cy": "13.296",
        "r": "13.296",
        "key": 1
    }), React.createElement("circle", {
        "transform": "rotate(-45 13.296 13.296)",
        "cx": "13.296",
        "cy": "13.296",
        "r": "6.205",
        "key": 2
    })]));
}

HireIcon6Inline.defaultProps = {"width": "30", "height": "30"};

module.exports = HireIcon6Inline;

HireIcon6Inline.default = HireIcon6Inline;
