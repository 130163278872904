const React = require('react');

function ArrowDownInline(props) {
    return React.createElement("svg", props, React.createElement("g", {
        "stroke": "#FFF",
        "strokeWidth": "2",
        "fill": "none",
        "fillRule": "evenodd",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
    }, React.createElement("path", {"d": "M5 0v14M10 9.1L5 14 0 9.1"})));
}

ArrowDownInline.defaultProps = {"width": "12", "height": "16"};

module.exports = ArrowDownInline;

ArrowDownInline.default = ArrowDownInline;
