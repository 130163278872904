const React = require('react');

function TechnologiesCss3Inline(props) {
    return React.createElement("svg", props, [React.createElement("defs", {"key": 0}, React.createElement("linearGradient", {
        "x1": "50%",
        "y1": "100%",
        "x2": "50%",
        "y2": "0%",
        "id": "css3a"
    }, [React.createElement("stop", {
        "stopColor": "#5E67DF",
        "offset": "0%",
        "key": 0
    }), React.createElement("stop", {
        "stopColor": "#00EBAA",
        "offset": "100%",
        "key": 1
    })])), React.createElement("path", {
        "d": "M0 0l5.552 62.105L30.462 69l24.979-6.905L61 0H0zm11.63 18.185l-.354-3.886-.185-2.057h38.818l-.185 2.057-1.905 21.124h-.003l-1.318 14.563-.116 1.296-15.847 4.346v.017h-.045l-.014-.004-15.862-4.366-1.087-12.035h7.774l.552 6.113 8.623 2.304.007.002h.013v-.007l8.644-2.306.903-9.923h-9.567v-.007h-8.532l-.15-1.721-.354-3.886-.185-2.058h19.476l.706-7.848H30.476v.004H11.79l-.16-1.722z",
        "fill": "url(#css3a)",
        "fillRule": "evenodd",
        "key": 1
    })]);
}

TechnologiesCss3Inline.defaultProps = {"width": "61", "height": "69", "viewBox": "0 0 61 69"};

module.exports = TechnologiesCss3Inline;

TechnologiesCss3Inline.default = TechnologiesCss3Inline;
