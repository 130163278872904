import {forwardRef} from "react";
import {LogoSign, HireIcon1, HireIcon2, HireIcon3, HireIcon4, HireIcon5, HireIcon6} from "../../Static/Svg";

const Hire = forwardRef(({props}, ref) => {
    return (
        <section className="hire " id="process" data-aos="" ref={ref}>
            <div className="container">
                <div className="circle hire__circle-small"></div>
                <div className="row">
                    <div className="col-xs-12 col-md-5">
                        <h3 className="g-h4 " data-aos="fade-up">
                            Hire a team of experienced engineers for your project
                        </h3>
                        <p className="g-p2 " data-aos="fade-up" data-aos-delay="150">
                            We aim high while paying attention to the smallest details. We work in precisely planned
                            process to scope, design, develop, and launch a scalable software that will help you conquer
                            the markets.
                        </p>
                        <ul className="g-list">
                            <li data-aos="fade-up" data-aos-delay="200" className="">
                                Transparent, iterative process
                            </li>
                            <li data-aos="fade-up" data-aos-delay="300" className="">
                                Quality-driven approach
                            </li>
                            <li data-aos="fade-up" data-aos-delay="400" className="">
                                Agile work management
                            </li>
                            <li data-aos="fade-up" data-aos-delay="500" className="">
                                Focus on partnership
                            </li>
                            <li data-aos="fade-up" data-aos-delay="600" className="">
                                User-centered attitude
                            </li>
                        </ul>
                    </div>

                    <div className="col-xs-12 col-md-6 hire__right">

                        <div className="hire__circle">

                            <div className="hire__circle-inner">
                                <LogoSign/>
                            </div>
                        </div>
                        <ul className="hire__list">
                            <li>
                                <HireIcon1/>
                                <p className="hire__list-title">Planning</p>
                                <p className="hire__list-desc">
                                    Discovery Phase
                                    <br/>
                                    UX research
                                </p>
                            </li>
                            <li>
                                <HireIcon2/>
                                <p className="hire__list-title">
                                    Design
                                </p>
                                <p className="hire__list-desc">
                                    Wireframing
                                    <br/>
                                    Prototyping
                                </p>
                            </li>
                            <li>
                                <HireIcon3/>
                                <p className="hire__list-title">
                                    Development
                                </p>
                                <p className="hire__list-desc">
                                    Front end
                                    <br/>
                                    Back end
                                </p>
                            </li>
                            <li>
                                <HireIcon4/>
                                <p className="hire__list-title">
                                    Testing
                                </p>
                                <p className="hire__list-desc">
                                    Quality assurance
                                    <br/>
                                    Optimization
                                </p>
                            </li>
                            <li>
                                <HireIcon5/>
                                <p className="hire__list-title">
                                    Launch
                                </p>
                                <p className="hire__list-desc">
                                    Final quality check
                                    <br/>
                                    Product release
                                </p>
                            </li>
                            <li>
                                <HireIcon6/>
                                <p className="hire__list-title">
                                    Support
                                </p>
                                <p className="hire__list-desc">
                                    Ongoing support
                                    <br/>
                                    Maintenance
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
});
export default Hire