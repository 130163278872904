import {useLocation} from "react-router-dom";

function NavBar(props) {
    const location = useLocation();

    if (location.pathname === "/") {

        return ( <nav className="header__nav">
                <ul className="header__nav-list">
                    <li onClick={props.AboutBackClick}>
                        <button type="button" data-section="about">About</button>
                    </li>
                    <li onClick={props.ServicesBackClick}>
                        <button type="button" data-section="services">Services</button>
                    </li>
                    <li onClick={props.TechnologiesBackClick}>
                        <button type="button" data-section="technologies">Technologies</button>
                    </li>
                    <li onClick={props.ProcessBackClick}>
                        <button type="button" data-section="process">Process</button>
                    </li>
                </ul>
                <a href="mailto:info@codrex.co" className="g-button g-button--small g-button--outlined"
                   title="Hire us">
                    <span>Hire us!</span>
                </a>
            </nav>

        )
    } else {
        return (
            <nav className="header__nav">
                {/*<ul className="header__nav-list">*/}
                {/*    <li onClick={props.AboutBackClick}>*/}
                {/*        <button type="button" data-section="about">About</button>*/}
                {/*    </li>*/}
                {/*    <li onClick={props.ServicesBackClick}>*/}
                {/*        <button type="button" data-section="services">Services</button>*/}
                {/*    </li>*/}
                {/*    <li onClick={props.TechnologiesBackClick}>*/}
                {/*        <button type="button" data-section="technologies">Technologies</button>*/}
                {/*    </li>*/}
                {/*    <li onClick={props.ProcessBackClick}>*/}
                {/*        <button type="button" data-section="process">Process</button>*/}
                {/*    </li>*/}
                {/*</ul>*/}
                <a href="mailto:info@codrex.co" className="g-button g-button--small g-button--outlined"
                   title="Hire us">
                    <span>Hire us!</span>
                </a>
            </nav>)

    }

}

export default NavBar

