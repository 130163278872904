var React = require('react');

function HireIcon5Inline (props) {
    return React.createElement("svg",props,React.createElement("g",{"transform":"translate(1.349 1.305)","stroke":"#00EBAA","strokeWidth":"2","fill":"none","fillRule":"evenodd","strokeLinecap":"round","strokeLinejoin":"round"},[React.createElement("path",{"d":"M17.103 2.112l6.909 6.914M14.304 3.574c-4.035-1.462-8.248-.588-11.796 4.109l3.693 3.705M14.77 19.963l3.702 3.696c4.694-3.55 5.568-7.766 4.106-11.805","key":0}),React.createElement("path",{"d":"M11.377 21.884l-7.095-7.1S10.041.84 26.149 0c-.91 16.052-14.772 21.884-14.772 21.884z","key":1}),React.createElement("ellipse",{"cx":"15.368","cy":"10.789","rx":"2.217","ry":"2.219","key":2}),React.createElement("path",{"d":"M1.514 20.89a2.66 2.66 0 013.73.032 2.664 2.664 0 01.032 3.732C4.238 25.694 0 26.17 0 26.17s.474-4.24 1.514-5.28z","key":3})]));
}

HireIcon5Inline.defaultProps = {"width":"30","height":"30"};

module.exports = HireIcon5Inline;

HireIcon5Inline.default = HireIcon5Inline;
