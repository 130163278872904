import "./Vision.css"
import {forwardRef} from "react";
import {VisionIcon1,VisionIcon2,VisionIcon3} from "../../Static/Svg";

const Vision = forwardRef(
    ({props}, ref) => {

        return (

            <section className="vision" id="services" ref={ref}>

                <div className="container">

                    <div className="Plx Plx--active Plx--in Plx--in-0 vision__circles Custem-1"/>
                    <div className="Plx Plx--active Plx--in Plx--in-0 vision__circle Custem-2"/>

                    <div className="row">

                        <div className="col-xs-12 col-md-8 col-md-offset-2">

                            <p className="g-subtitle" data-aos="">
                                Our vision
                            </p>

                            <h2 className="g-h2 " data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                                We want to equip your business with flawless design and solid technology
                            </h2>

                            <p className="g-p1 " data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
                                You are the one who is steering your business. What we provide is safe, solid, and fast
                                technology, as well as trusted staff that will support you on every stage. Let's build
                                your next interactive web application together.
                            </p>

                        </div>
                    </div>
                    <div className="row vision__icons">

                        <div className="col-xs-12 col-sm-4">

                            <div className="vision__icons-icon " data-aos="">
                                <VisionIcon1/>

                            </div>

                            <p className="g-p1 color-white " data-aos="" data-aos-delay="75">
                                <span>Discovery Phase</span>
                            </p>

                            <p className="g-p2 " data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000">
                                That’s where you tell us where you want to go. Together with our best experts on design,
                                development, and project management, you decide on the most efficient solutions that
                                will be tailored to your needs.
                            </p>

                        </div>
                        <div className="col-xs-12 col-sm-4">

                            <div className="vision__icons-icon " data-aos="" data-aos-delay="300">
                                <VisionIcon2/>
                            </div>

                            <p className="g-p1 color-white " data-aos="" data-aos-delay="375">
                                <span>Product Design</span>
                            </p>

                            <p className="g-p2 e" data-aos="fade-up" data-aos-delay="450"
                               data-aos-duration="1000">That
                                phase is all about your clients. We define users’ personas and create a user's journey
                                to discover the most engaging design that will attract clients to your brand like a
                                magnet.
                            </p>

                        </div>
                        <div className="col-xs-12 col-sm-4">

                            <div className="vision__icons-icon " data-aos="" data-aos-delay="600">
                                <VisionIcon3/>

                            </div>

                            <p className="g-p1 color-white " data-aos="" data-aos-delay="675">
                                <span>Software Development</span>
                            </p>

                            <p className="g-p2 " data-aos="fade-up" data-aos-delay="750" data-aos-duration="1000">
                                Our main focus is to combine the highest quality code with the lowest technical debt,
                                ensuring the best security and stability.
                                We make sure that your product will be easily scalable in the future.
                            </p>

                        </div>

                    </div>

                </div>

            </section>
        )
    }
);

export default Vision