const React = require('react');

function TwitterInline (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M7.8,19.2c8.5,0,13.1-7,13.1-13.1c0-0.2,0-0.4,0-0.6c0.9-0.6,1.7-1.5,2.3-2.4\n\tc-0.8,0.4-1.7,0.6-2.7,0.7c1-0.6,1.7-1.5,2-2.6c-0.9,0.5-1.9,0.9-2.9,1.1c-0.8-0.9-2-1.5-3.4-1.5c-2.5,0-4.6,2.1-4.6,4.6\n\tc0,0.4,0,0.7,0.1,1.1C8,6.4,4.6,4.6,2.3,1.8C1.9,2.5,1.7,3.2,1.7,4.1c0,1.6,0.8,3,2.1,3.8C3,7.9,2.3,7.7,1.7,7.4c0,0,0,0,0,0.1\n\tc0,2.2,1.6,4.1,3.7,4.5c-0.4,0.1-0.8,0.2-1.2,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,1.8,2.3,3.2,4.3,3.2c-1.6,1.2-3.6,2-5.7,2\n\tc-0.4,0-0.7,0-1.1-0.1C2.8,18.5,5.2,19.2,7.8,19.2"}));
}

TwitterInline.defaultProps = {"version":"1.1","x":"0px","y":"0px","viewBox":"0 0 24 20","style":{"enableBackground":"new 0 0 24 20"},"xmlSpace":"preserve","width":"24","height":"24"};

module.exports = TwitterInline;

TwitterInline.default = TwitterInline;
