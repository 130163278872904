const React = require('react');

function FacebookInline (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M22 .7H2C1.3.7.8 1.3.8 1.9v20c0 .7.6 1.2 1.2 1.2h10.8v-8.7h-3V11h2.9V8.6c0-2.9 1.8-4.5 4.4-4.5.9 0 1.7 0 2.6.1v3H18c-1.4 0-1.7.7-1.7 1.7v2.2h3.4l-.4 3.4h-3v8.7H22c.7 0 1.2-.6 1.2-1.2V2c0-.8-.5-1.3-1.2-1.3z"}));
}

FacebookInline.defaultProps = {"width":"24","height":"24"};

module.exports = FacebookInline;

FacebookInline.default = FacebookInline;
