import {forwardRef} from "react";

const  About = forwardRef(({ props }, ref) => {
    return (<section className="about" id="about" ref={ref}>
        <div className="container">
            <div className="hero__hands">
                <div className="circle hero__hands-circle-1"></div>
                <div className="circle hero__hands-circle-2"></div>
                <p className="g-subtitle  " data-aos="">Your project in good hands</p>
                <div className="hero__hands-row"><p className="g-h3 " data-aos="fade-up"
                                                    data-aos-delay="300">As a software house, we provide UX/UI design
                    services and full-stack engineering solutions that will suit your business.</p><p
                    className="g-p1  " data-aos="fade-up" data-aos-delay="600">Our experts will grant
                    efficient design and agile development for long-lasting products so that you can focus on defining
                    further goals. We support you in your marathon to global success.</p></div>
            </div>
        </div>
    </section>)

});
export default About