import "./Footer.css"
import {FacebookIcon} from "../../Static/Svg";
import linkedinIcon from "../../Static/Svg/linkin .svg"
function Footer() {
    return (<footer className="footer">
        <div className="container">
            <p className="footer__copyright">
                © 2022 Codrex .
                <br/>
                All right reserved.
            </p>
            <p className="footer__socials">
                <a href="https://www.facebook.com/codrexco" target="_blank"
                   rel="noopener noreferrer" className="footer__social-icon"
                   title="Facebook">
                    <FacebookIcon/>
                </a>
                <a href="https://www.linkedin.com/company/codrex-co/" target="_blank" rel="noopener noreferrer"
                   className="footer__social-icon" title="Linkedin">
                    {/*<TwitterIcon/>*/}
                    <img src={linkedinIcon} alt={"linkedin"} style={{width:24,height:24}}/>
                </a>
            </p>
            <ul className="footer__nav">
                <li>
                    <a direction="right" bg="#00EBAA" title="Terms of Use" href="/terms-of-use/">
                        Terms of Use
                    </a>
                </li>
                <li>
                    <a direction="right" bg="#00EBAA" title="Privacy policy" href="/privacy-policy/">
                        Privacy policy
                    </a>
                </li>
            </ul>
        </div>
    </footer>)
}

export default Footer