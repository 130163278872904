var React = require('react');

function HireIcon3Inline (props) {
    return React.createElement("svg",props,React.createElement("g",{"stroke":"#00EBAA","strokeWidth":"2","fill":"none","fillRule":"evenodd","strokeLinecap":"round","strokeLinejoin":"round"},[React.createElement("path",{"d":"M1.238 1h26.524v22.952H1.238zM1.238 5.414h26.524M6.543 1v4.414","key":0}),React.createElement("path",{"d":"M10.963 11.593l-2.652 2.648 2.652 2.649M18.037 11.593l2.652 2.648-2.652 2.649","key":1})]));
}

HireIcon3Inline.defaultProps = {"width":"30","height":"25"};

module.exports = HireIcon3Inline;

HireIcon3Inline.default = HireIcon3Inline;
