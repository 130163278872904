var React = require('react');

function LogoInline(props) {
    return React.createElement("svg", props, [React.createElement("defs", {"key": 0}, [React.createElement("linearGradient", {
        "x1": "100%",
        "y1": "90.302%",
        "x2": "8.556%",
        "y2": "15.797%",
        "id": "f",
        "key": 0
    }, [React.createElement("stop", {
        "stopColor": "#FFF",
        "offset": "0%",
        "key": 0
    }), React.createElement("stop", {
        "stopColor": "#FFF",
        "stopOpacity": "0",
        "offset": "100%",
        "key": 1
    })]), React.createElement("linearGradient", {
        "x1": "53.915%",
        "y1": "0%",
        "x2": "43.504%",
        "y2": "93.677%",
        "id": "g",
        "key": 1
    }, [React.createElement("stop", {
        "stopColor": "#FFF",
        "offset": "0%",
        "key": 0
    }), React.createElement("stop", {
        "stopColor": "#FFF",
        "stopOpacity": "0",
        "offset": "100%",
        "key": 1
    })]), React.createElement("linearGradient", {
        "x1": "1.862%",
        "y1": "58.824%",
        "x2": "93.342%",
        "y2": "45.882%",
        "id": "h",
        "key": 2
    }, [React.createElement("stop", {
        "stopColor": "#FFF",
        "offset": "0%",
        "key": 0
    }), React.createElement("stop", {
        "stopColor": "#FFF",
        "stopOpacity": "0",
        "offset": "100%",
        "key": 1
    })]), React.createElement("path", {
        "id": "a",
        "d": "M0 .075h7.886v10.602H0z",
        "key": 3
    }), React.createElement("path", {
        "id": "c",
        "d": "M.192.077h10.659v15.009H.192z",
        "key": 4
    }), React.createElement("radialGradient", {
        "cx": "52.918%",
        "cy": "50%",
        "fx": "52.918%",
        "fy": "50%",
        "r": "53.12%",
        "gradientTransform": "matrix(-.6987 .708 -.89017 -.8975 1.344 .574)",
        "id": "e",
        "key": 5
    }, [React.createElement("stop", {
        "stopColor": "#FFF",
        "offset": "0%",
        "key": 0
    }), React.createElement("stop", {
        "stopColor": "#FFF",
        "stopOpacity": "0",
        "offset": "100%",
        "key": 1
    })])]), React.createElement("g", {
        "fill": "none",
        "fillRule": "evenodd",
        "key": 1
    }, [React.createElement("g", {
        "transform": "translate(46 10.114)",
        "key": 0
    }, [React.createElement("mask", {
        "id": "b",
        "fill": "#fff",
        "key": 0
    }, React.createElement("use", {"xlinkHref": "#a"})), React.createElement("path", {
        "d": "M3.994 4.413c-1.188-.399-1.986-.724-1.986-1.52 0-.776.753-1.165 1.605-1.165a4.28 4.28 0 011.997.476.941.941 0 001.061-.15c.433-.405.318-1.08-.22-1.351C5.558.252 4.643.075 3.681.075 1.617.075.014 1.32.014 2.892c0 1.865 1.58 2.608 3.353 3.195 1.593.545 2.525.725 2.525 1.625 0 .776-.686 1.312-2.01 1.312-1.069 0-1.862-.226-2.535-.629a.92.92 0 00-1.09.102.775.775 0 00.135 1.25c.946.554 2.119.93 3.49.93 2.626 0 4.004-1.393 4.004-2.986.022-1.99-1.58-2.44-3.892-3.278",
        "fill": "#FFF",
        "mask": "url(#b)",
        "key": 1
    })]), React.createElement("path", {
        "d": "M64.573 12.594a.8.8 0 00-.052-1.213 5.88 5.88 0 00-3.627-1.267c-3.281 0-5.8 2.233-5.8 5.421 0 3.21 2.519 5.55 5.732 5.55 1.662 0 2.87-.466 3.762-1.169a.792.792 0 00.044-1.2l-.091-.087a.894.894 0 00-1.14-.062c-.677.49-1.59.764-2.595.764-2.24 0-3.678-1.465-3.678-3.72 0-2.234 1.593-3.742 3.857-3.742.983 0 1.752.316 2.372.803.349.276.853.27 1.173-.037l.043-.041zM71.23 19.332c-2.231 0-3.734-1.617-3.734-3.72 0-2.105 1.48-3.744 3.735-3.744 2.077 0 3.637 1.487 3.637 3.743 0 2.255-1.582 3.72-3.637 3.72m0 1.755c3.182 0 5.547-2.265 5.547-5.475 0-3.21-2.365-5.497-5.547-5.497-3.338 0-5.645 2.46-5.645 5.497 0 3.036 2.307 5.475 5.645 5.475",
        "fill": "#FFF",
        "key": 1
    }), React.createElement("g", {
        "transform": "translate(78.876 10.114)",
        "key": 2
    }, React.createElement("path", {
        "d": "M5.479 9.263c-1.987 0-3.495-1.417-3.495-3.644 0-2.16 1.358-3.794 3.474-3.794 1.965 0 3.494 1.46 3.494 3.794 0 2.118-1.38 3.644-3.473 3.644M.192 1.118v13.055c0 .505.404.913.902.913a.907.907 0 00.902-.913V9.065h.021c.77 1.21 2.052 1.945 3.696 1.945 2.863 0 5.138-2.192 5.138-5.39 0-3.113-2.188-5.543-5.138-5.543-1.58 0-2.905.735-3.696 1.989l-.02.073V1.12a.907.907 0 00-.903-.913.907.907 0 00-.902.912",
        "fill": "#FFF"
    })), React.createElement("path", {
        "d": "M103.426 11.994l3.536 8.272c.212.495.724.82 1.293.82.57 0 1.081-.325 1.293-.82l3.536-8.272c.244-.572-.205-1.194-.862-1.194a.93.93 0 00-.866.556l-3.079 7.442h-.022l-3.079-7.442a.93.93 0 00-.865-.556h-.022c-.658 0-1.107.622-.863 1.194M115.73 11.299v8.913c0 .483.407.874.908.874.501 0 .907-.391.907-.874V11.3c0-.483-.406-.874-.907-.874-.5 0-.908.391-.908.874m.92-2.647c.739 0 1.398-.635 1.398-1.326 0-.691-.66-1.326-1.399-1.326-.74 0-1.399.635-1.399 1.326 0 .69.66 1.326 1.4 1.326M125.754 19.332c-2.218 0-3.635-1.617-3.635-3.72 0-2.105 1.395-3.744 3.635-3.744 2.063 0 3.612 1.487 3.612 3.743 0 2.255-1.571 3.72-3.612 3.72m0 1.755c3.16 0 5.584-2.265 5.584-5.475 0-3.21-2.424-5.497-5.584-5.497-3.315 0-5.607 2.46-5.607 5.497 0 3.036 2.292 5.475 5.607 5.475M93.663 14.625c-.081 0-.143-.073-.126-.148.38-1.704 1.67-2.609 3.372-2.609 1.745 0 2.824.966 3.13 2.613.015.075-.046.144-.125.144h-6.251zm8.297.79c0-3.014-2.043-5.3-5.028-5.3-3.12 0-5.465 2.307-5.465 5.495 0 3.232 2.367 5.476 5.599 5.476 1.724 0 2.987-.54 3.905-1.337a.775.775 0 00.025-1.15l-.115-.11a.853.853 0 00-1.125-.034c-.646.523-1.51.876-2.623.876-2.015 0-3.359-1.097-3.651-2.984-.012-.074.05-.142.126-.142h7.532c.45 0 .819-.355.82-.79zM9.886 13.002c-.251-.578-.39-1.24-.39-2.003 0-2.89 2.85-5.18 6.52-5.18 1.71 0 3.381.284 4.97 1.092.02.01.035.02.054.031.554.256.939.801.939 1.434 0 .879-.738 1.59-1.646 1.59-.316 0-.608-.086-.858-.234l-.014-.007c-1.189-.637-2.426-.82-3.65-.785a5.595 5.595 0 00-.69.062c-2.539.328-4.409 1.867-5.235 4",
        "fill": "#FFF",
        "key": 3
    }), React.createElement("path", {
        "d": "M13.483 10.837c0-1.273.615-1.686 1.847-1.882-2.629.349-4.592 1.788-5.444 4.047.91 2.127 3.566 3.156 6.093 4.042 2.927 1.058 4.64 1.407 4.64 3.157 0 1.194-.685 2.188-2.156 2.49 2.72-.497 4.796-2.292 5.307-5.22-1.18-1.51-3.82-2.6-6.638-3.68-2.184-.774-3.65-1.406-3.65-2.954z",
        "fill": "url(#e)",
        "key": 4
    }), React.createElement("path", {
        "d": "M9.472 27.476C5.541 25.011 3.31 20.97 3.084 16.774c-.039-.713-.733-1.222-1.46-1.075-.584.118-.996.628-.965 1.202.267 4.914 2.881 9.645 7.485 12.533a16.915 16.915 0 008.327 2.565c.792.032 1.405-.663 1.242-1.41-.115-.524-.585-.9-1.142-.923a14.442 14.442 0 01-7.099-2.19",
        "fill": "url(#f)",
        "key": 5
    }), React.createElement("path", {
        "d": "M30.865 24.9c3.302-4.897 3.42-10.927.852-15.795-.413-.784-1.56-.825-2.058-.088l-.002.003c-.23.34-.265.774-.074 1.136 2.194 4.155 2.098 9.3-.722 13.48a13.628 13.628 0 01-4.617 4.251c-.444.25-.672.746-.566 1.232.168.771 1.068 1.161 1.772.764a15.96 15.96 0 005.415-4.983",
        "fill": "url(#g)",
        "key": 6
    }), React.createElement("path", {
        "d": "M25.227 2.048C17.913-2.058 8.537.17 3.889 7.299c-.158.242-.308.487-.45.735-.31.54-.136 1.23.386 1.57A1.178 1.178 0 005.492 9.2c.118-.206.242-.41.374-.611C10 2.245 18.524.449 24.864 4.587c.046.03.088.062.134.092l.625-.96-.396-1.671",
        "fill": "url(#h)",
        "key": 7
    }), React.createElement("path", {
        "d": "M27.274 4.989a1.978 1.978 0 01-2.727.57 1.951 1.951 0 01-.574-2.71 1.978 1.978 0 012.727-.571 1.952 1.952 0 01.574 2.71M3.622 16.744a1.978 1.978 0 01-2.728.57 1.952 1.952 0 01-.574-2.71 1.978 1.978 0 012.728-.571 1.952 1.952 0 01.574 2.71M25.303 30.458a1.978 1.978 0 01-2.728.57 1.952 1.952 0 01-.574-2.71 1.978 1.978 0 012.728-.571 1.952 1.952 0 01.574 2.711M21.024 16c0 1.983-1.618 3.592-3.614 3.592-1.995 0-3.613-1.609-3.613-3.592 0-1.984 1.618-3.592 3.613-3.592 1.996 0 3.614 1.608 3.614 3.592",
        "fill": "#FFF",
        "key": 8
    }), React.createElement("path", {
        "d": "M18.67 22.64l-.012.003c-.413.092-.86.14-1.326.14-1.943 0-3.157-.461-4.375-1.287l-.056-.034c-.28-.193-.62-.31-.99-.31-.931 0-1.685.722-1.685 1.612 0 .504.242.953.62 1.248.062.058.13.113.205.161 1.631 1.058 3.507 1.771 6.35 1.771 4.052 0 7.279-2.597 7.279-5.566.013-1.26-.32-2.185-.923-2.923l.013.016c-.498 2.761-2.478 4.652-5.1 5.169z",
        "fill": "#FFF",
        "key": 9
    })])]);
}

LogoInline.defaultProps = {"width": "132", "height": "32"};

module.exports = LogoInline;

LogoInline.default = LogoInline;
