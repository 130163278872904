var React = require('react');

function HireIcon1Inline(props) {
    return React.createElement("svg", props, React.createElement("g", {
        "stroke": "#00EBAA",
        "strokeWidth": "2",
        "fill": "none",
        "fillRule": "evenodd",
        "strokeLinecap": "round",
        "strokeLinejoin": "round"
    }, [React.createElement("path", {
        "d": "M7.178 12.536h10.643M7.178 16.974h10.643M7.178 21.411h4.435M15.16 3.662a2.661 2.661 0 10-5.32 0H7.178v3.55H17.82v-3.55h-2.66z",
        "key": 0
    }), React.createElement("path", {"d": "M21.369 4.55h1.774v23.073H1.857V4.55h1.774", "key": 1})]));
}

HireIcon1Inline.defaultProps = {"width": "25", "height": "29"};

module.exports = HireIcon1Inline;

HireIcon1Inline.default = HireIcon1Inline;
