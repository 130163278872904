export const EMAIL = 'info@codrex.co';

export const scrollToId = (destination, duration = 200, easing = 'linear') => {
    const easings = {
        linear(t) {
            return t;
        },
    };

    const start = window.pageYOffset;
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

    const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
    const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
    const destinationOffsetToScroll = (window.width >= 767) ? Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset) : Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset) - 70; // on mobile add extra spaces

    if (!('requestAnimationFrame' in window)) {
        window.scroll(0, destinationOffsetToScroll);
        return;
    }

    let lastScrollPosition;
    function scroll() {
        let now = 'now' in window.performance ? performance.now() : new Date().getTime();
        let time = Math.min(1, ((now - startTime) / duration));
        let timeFunction = easings[easing](time);

        window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

        if (lastScrollPosition === window.pageYOffset || Math.abs(window.pageYOffset - destinationOffsetToScroll) <= 1) {
            return;
        }

        lastScrollPosition = window.pageYOffset;
        requestAnimationFrame(scroll);
    }

    scroll();
}


export const setCookie = ({ name = null, value = null }) => {
    if(!name.trim() || !value) {
        // eslint-disable-next-line no-console
        console.warn('Provided value or name for cookies cannot be null!');
        return;
    }
    document.cookie = `${name}=${value}`;
};

export const deleteCookie = name => {
    if(!name.trim()) {
        // eslint-disable-next-line no-console
        console.warn('You have to provide name for cookie to delete!');
        return;
    }
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

export const getCookie = (cookieName) => {
    if(!cookieName.trim()) {
        // eslint-disable-next-line no-console
        console.warn('You have to provide name for cookie to delete!');
        return;
    }
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const checkIfCookieExists = (name) =>  {
    if(!name.trim()) {
        // eslint-disable-next-line no-console
        console.warn('You have to provide name for cookie to delete!');
        return;
    }
    return getCookie(name) !== "";
};

export const parallaxCircles1 = [
    {
        start: 0,
        end: 900,
        properties: [
            {
                startValue: 0,
                endValue: 300,
                property: 'translateY',
            },
        ],
    },
];

export const parallaxCircles2 = [
    {
        start: 0,
        end: '.footer',
        properties: [
            {
                startValue: 400,
                endValue: -300,
                property: 'translateY',
            },
        ],
    },
];

export const parallaxRotate = [
    {
        start: '.header',
        end: '.footer',
        properties: [
            {
                startValue: -30,
                endValue: 30,
                unit: 'deg',
                property: 'rotate',
            },
            {
                startValue: -900,
                endValue: 500,
                property: 'translateY',
            },
        ],
    },
];