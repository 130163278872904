import {ArrowDown} from "../../Static/Svg";

function Hero(props) {
    return (<section className="hero">
        <div className="container">
            <div className="Plx Custem-1">
                <div className="hero__circle " data-aos="zoom-in"></div>
            </div>
            <div className="row hero__row">
                <div className="col-xs-12 col-md-6 hero__left">
                    <h1 className="g-h1 " data-aos="">
                        <p>
                            <span>We build interactive</span>
                        </p>
                        <p>
                            <span>web applications</span>
                        </p>
                    </h1>
                    <p className="g-p2 " data-aos="fade-up" data-aos-duration=".6s"
                       data-aos-delay="200">
                        Let’s create scalable web applications together and give your concept the wings it deserves.
                    </p>
                    <div className="button-wrapper " data-aos="fade-up" data-aos-duration=".6s"
                         data-aos-delay="400">
                        <a href="mailto:info@codrex.co" className="g-button" title="Send us a email">
                            Get in
                        </a>
                    </div>
                    <div className="button-wrapper" data-aos="fade-up" data-aos-duration=".6s" data-aos-delay="600">
                        <button type="button" className="g-button g-button--outlined" aria-label="Learn more"
                                onClick={props.AboutBackClick}>
                            Learn more
                        </button>
                    </div>

                    <button data-aos="fade-up" type="button" className="hero__scroll "
                            onClick={props.AboutBackClick}
                            aria-label="Scroll to next section">
                        <ArrowDown/>
                    </button>
                </div>
                <div className="hero__image"></div>
            </div>
        </div>
    </section>)

}

export default Hero