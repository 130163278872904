import "./Header.css"

import NavBar from "./Header Components/NavBar";
import {useState} from "react";
import logo_codrex from "../../Static/Svg/codrex logo.svg"


function Header(props) {
    const [state, setstate] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(current => !current);
    };
    const changeClass = () => {
        const scrollValue = document.documentElement.scrollTop;
        if (scrollValue > 50) {
            setstate(true);
        } else {
            setstate(false);
        }

    }
    window.addEventListener('scroll', changeClass);


    return (
        <header id="header"
                className={state ? isActive ? "header is-sticky burger-show" : "header is-sticky " : isActive ? "header burger-show" : "header"}>
            <div className="container">
                <a aria-current="page" className="" direction="right" bg="#00EBAA" title="Back to home" href="/">

                    <img src={logo_codrex} alt={"Logo-Codrex"} style={{width: 132, height: 32}}/>
                </a>
                <button type="button" className={isActive ? "burger is-active" : "burger"} aria-label="Expand menu"
                        onClick={handleClick}>
                    <i></i>
                </button>


                <NavBar ProcessBackClick={props.ProcessBack} TechnologiesBackClick={props.Technologies}
                        AboutBackClick={props.AboutBack} ServicesBackClick={props.ServicesBack}/>
            </div>
        </header>)


}

export default Header