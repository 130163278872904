import {forwardRef} from "react";
import {Html5Icon, CSS3Icon, ReactIcon, NodeIcon, PythonIcon,UnityIcon,SwiftIcon,FlutterIcon} from "../../Static/Svg";

const Tecnologies = forwardRef(({props}, ref) => {
    return (
        <section className="technologies" id="technologies" ref={ref}>

            <div className="container">

                <div className="circle technologies__circle-1"/>
                <div className="circle technologies__circle-2"/>
                <div className="circle technologies__circle-3"/>
                <div className="circle technologies__circle-4"/>

                <div className="row">

                    <div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                        <h3 className="g-h4 " data-aos="fade-up">
                            <p>
                                <span>Technologies we use</span>
                            </p>
                        </h3>
                        <p className="g-p1 " data-aos='fade-up'>
                            We use only proven, top-notch technologies to grant you security, scalability, and flawless
                            experience.
                        </p>
                    </div>
                    <div className="col-xs-12">

                        <ul className="technologies__list" style={{listStyleType: "none"}}>

                            <li data-aos="fade-up" data-aos-delay="0">
                                <Html5Icon/>
                                HTML5
                            </li>
                            <li data-aos="fade-up" data-aos-delay="100">
                                <CSS3Icon/>
                                CSS3
                            </li>
                            <li data-aos="fade-up" data-aos-delay="200">
                                <ReactIcon/>
                                React.js
                            </li>
                            <li data-aos="fade-up" data-aos-delay="300">
                                <NodeIcon/>
                                Node.js
                            </li>
                            <li data-aos="fade-up" data-aos-delay="400">
                                <PythonIcon/>
                                Python
                            </li>
                            <li data-aos="fade-up" data-aos-delay="600">
                                <UnityIcon/>
                                Unity
                            </li>
                            <li data-aos="fade-up" data-aos-delay="700">
                                <SwiftIcon/>
                                Swift
                            </li>
                            <li data-aos="fade-up" data-aos-delay="800">
                                <FlutterIcon/>
                                Flutter
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
});

export default Tecnologies