import {useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
function Touch() {
    useEffect(() => {
        AOS.init({
            once: true,
            duration: 1000,

        });
    }, [])
    return (
        <section className="touch">

            <div className="container">

                <div className="circle touch__circle-1 " data-aos="zoom-in"/>

                <div className="touch__circle-2 " data-aos="zoom-in"/>

                <div className="col-xs-12 col-md-6 col-md-offset-2">


                    <div className="circle touch__circle"/>

                    <h4 className="g-h4 " data-aos="">

                        <p>

                    <span>
                        From start-ups to large enterprises
                    </span>

                        </p>

                    </h4>

                    <p className="g-p1 " data-aos="fade-up">
                        Tell us about your needs. Together we can build a long-term partnership that will take your
                        business to the bright future.
                    </p>
                </div>

                <div className="col-xs-12 col-md-2 touch__right" data-aos="fade-left">

                    <a href="mailto:info@codrex.co" title="Get in touch" className="g-button">
                        Get in touch
                    </a>

                </div>

            </div>

        </section>
    )

}

export default Touch