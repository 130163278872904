import {Header, Footer, Touch, Hire, Tecnologies, Vision, About, Hero} from "../Components";
import {useEffect, useRef} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./main page.css"


function MainPage() {
    useEffect(() => {
        AOS.init({
            once: true,
            duration: 1000,

        });
    }, [])

    const TechUseRef = useRef()

     function handleBackTech() {
        console.log("TechUseRef")
        TechUseRef.current.scrollIntoView({behavior: 'smooth'})

    }

    const AboutUseRef = useRef()

     function handleBackAbout() {
        console.log(AboutUseRef)
        AboutUseRef.current.scrollIntoView({behavior: 'smooth', block: "start", inline: "nearest"})
    }

    const ProcessUseRef = useRef()

     function handleBackProcess() {
        console.log(ProcessUseRef)
        ProcessUseRef.current.scrollIntoView({behavior: 'smooth'})
    }

    const ServisesUseRef = useRef()

     function handleBackServices() {
        console.log(ServisesUseRef)
        ServisesUseRef.current.scrollIntoView({behavior: 'smooth'})
    }

    return (
        <>
            <div data-aos-easing="ease" data-aos-duration="1000" data-aos-delay="0">
                <noscript id="gatsby-noscript">This app works best with JavaScript enabled.</noscript>
                <div id="___gatsby">
                    <div className="CustemApp-2" tabIndex="-1" id="gatsby-focus-wrapper">
                        <div className="tl-edges">
                            <div className="tl-wrapper tl-wrapper--mount tl-wrapper-status--entered CustemApp-1">
                                <main className="main">
                                    <Header ProcessBack={handleBackProcess} ServicesBack={handleBackServices}
                                            AboutBack={handleBackAbout} Technologies={handleBackTech}/>
                                    <Hero AboutBackClick={handleBackAbout}/>

                                    <br ref={AboutUseRef}/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <About/>

                                    <br ref={ServisesUseRef}/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <Vision/>


                                    <Tecnologies ref={TechUseRef}/>


                                    <br ref={ProcessUseRef}/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>

                                    <Hire/>

                                    <Touch/>
                                    <Footer/>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<Cookies/>*/}
        </>


    );
}

export default MainPage;
